import React, { useEffect } from 'react';
import Container from 'components/UI/Container';

import { ReactComponent as BlackbaudSVG } from 'assets/svgs/blackbaud.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getBlackbaudCallback } from 'api/oauth';

const ConnectBlackbaudPage = () => {
	const navigate = useNavigate();
	let [searchParams] = useSearchParams();

	useEffect(() => {
		const secretKey = localStorage.getItem('Secret-Key') ?? undefined;
		const code = searchParams.get('code') ?? '';

		if (code) {
			(async function () {
				try {
					const planId = localStorage.getItem('planId') || '';
					const { data } = await getBlackbaudCallback(code, secretKey, planId);
					if (data?.secretKey) {
						console.log(data.secretKey);
						localStorage.setItem('Secret-Key', data.secretKey);
						navigate('/organisation');
					}
				} catch (err) {
					console.log(err);
				}
			})();
		}
	}, [searchParams, navigate]);
	return (
		<div className="p-5 bg-white">
			<Container>
				<h1 className="text-4xl text-center font-bold">Connecting to Blackbaud...</h1>
				<BlackbaudSVG className="w-20 h-20 mx-auto mt-8" />
			</Container>
		</div>
	);
};

export default ConnectBlackbaudPage;
