import request from 'api/request';

export const getHubspotCallback = (code: string, secretKey?: string, planId?: string) =>
	request({
		url: `/oauth/hubspot/callback?planId=${planId}`,
		method: 'GET',
		params: {
			code,
			secretKey,
		},
	});

export const getBlackbaudCallback = (code: string, secretKey?: string, planId?: string) =>
	request({
		url: `/oauth/blackbaud/callback?planId=${planId}`,
		method: 'GET',
		params: {
			code,
			secretKey,
		},
	});
