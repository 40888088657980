import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Container from 'components/UI/Container';

import { ReactComponent as HubspotSVG } from 'assets/svgs/hubspot.svg';
import { ReactComponent as BlackbaudSVG } from 'assets/svgs/blackbaud.svg';

import { ReactComponent as HelpSVG } from 'assets/svgs/help.svg';
import { getOrganisationBySecretKey } from 'api/organisation';

const OrganisationPage = () => {
	let navigate = useNavigate();
	const secretKey = localStorage.getItem('Secret-Key') ?? '';

	useEffect(() => {
		if (!secretKey) navigate('/');
	}, [navigate, secretKey]);

	const { data: organisation } = useQuery(['organisation', secretKey], async () => {
		const { data: res } = await getOrganisationBySecretKey(secretKey);
		if (!res.data) {
			localStorage.removeItem('Secret-Key');
			navigate('/');
		}

		return res.data;
	});

	return (
		<div className="p-5 bg-white">
			<Container>
				<div className="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 dark:bg-gray-800 dark:border-gray-700">
					<h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white">
						Integration
						{Boolean(organisation?.hubspot) && Boolean(organisation?.blackbaud) ? (
							<span className="bg-green-100 text-green-800 text-sm ml-3 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
								Active
							</span>
						) : (
							<span className="bg-red-100 text-red-800 text-sm ml-3 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
								Inactive
							</span>
						)}
					</h5>
					<p className="text-sm font-normal text-gray-500 dark:text-gray-400">
						Your secret key is: <b className="text-black">{organisation?.secretKey}</b>
					</p>
					<p className="text-sm font-bold mt-0.5 text-red-500">Please save your secret key!</p>
					<ul className="my-4 space-y-3">
						<li>
							<a
								href="/api/oauth/hubspot/url"
								className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
							>
								<HubspotSVG className="w-6 h-6" />
								<span className="flex-1 ml-3 whitespace-nowrap">HubSpot</span>
								{Boolean(organisation?.hubspot) ? (
									<span className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">
										Connected: {organisation?.hubspot.portalId}
									</span>
								) : (
									<span className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-red-200 rounded dark:bg-gray-700 dark:text-gray-400">
										Not Connected
									</span>
								)}
							</a>
						</li>
						<li>
							<a
								href="/api/oauth/blackbaud/url"
								className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
							>
								<BlackbaudSVG className="w-6 h-6" />
								<span className="flex-1 ml-3 whitespace-nowrap">Blackbaud</span>
								{Boolean(organisation?.blackbaud) ? (
									<span className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">
										Connected: {organisation?.blackbaud.entityName}
									</span>
								) : (
									<span className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-red-200 rounded dark:bg-gray-700 dark:text-gray-400">
										Not Connected
									</span>
								)}
							</a>
						</li>
					</ul>
					<div>
						<p className="inline-flex items-center text-xs font-normal text-gray-500 hover:underline dark:text-gray-400">
							<HelpSVG className="w-3 h-3 mr-2" />
							For integration to work please connect both hubspot and blackbaud accounts
						</p>
					</div>
				</div>

				<button
					onClick={() => {
						localStorage.removeItem('Secret-Key');
						navigate('/');
					}}
					className="mt-3 w-full  text-white  right-2.5 bottom-2.5 bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
				>
					Discard Integration
				</button>
			</Container>
		</div>
	);
};

export default OrganisationPage;
