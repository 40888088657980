import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OrganisationPage from 'containers/organisation';
import DashboardPage from 'containers/dashboard';
import ReactQueryProvider from 'components/providers/ReactQueryProvider';
import ConnectBlackbaudPage from 'containers/connect/blackbaud';
import ConnectHubspotPage from 'containers/connect/hubspot';

function App() {
	return (
		<ReactQueryProvider>
			<Router>
				<Routes>
					<Route path="/:planId?" element={<DashboardPage />} />
					<Route path="/organisation" element={<OrganisationPage />} />
					
					<Route path="/connect/blackbaud" element={<ConnectBlackbaudPage />} />
					<Route path="/connect/hubspot" element={<ConnectHubspotPage />} />

					<Route path="*" element={<DashboardPage />} />
				</Routes>
			</Router>
		</ReactQueryProvider>
	);
}

export default App;
