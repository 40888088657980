import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { getOrganisationBySecretKey } from 'api/organisation';
import Container from 'components/UI/Container';

import { ReactComponent as HubspotSVG } from 'assets/svgs/hubspot.svg';
import { ReactComponent as BlackbaudSVG } from 'assets/svgs/blackbaud.svg';
import { ReactComponent as SearchSVG } from 'assets/svgs/search.svg';

import LogoIMG from 'assets/img/logo.png';

const DashboardPage = () => {
	let navigate = useNavigate();
	const [search, setSearch] = useState('');
	const [error, setError] = useState('');
	let { planId } = useParams();

	useEffect(() => {
		const secretKey = localStorage.getItem('Secret-Key') ?? '';
		if (Boolean(secretKey)) navigate('/organisation');
	}, [navigate]);

	useEffect(() => {
		localStorage.setItem('planId', planId || '');
	}, []);

	return (
		<div className="p-5 bg-white">
			<Container>
				{/* Documentation */}
				<div className="mb-5 text-right">
					<a
						href="https://yodelpop.notion.site/RaiserSync-Installation-578c0f863cfa4c11bced63b858822b82?pvs=25"
						target="_blank"
						className="inline p-3 text-sm font-medium text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white cusror-pointer"
						rel="noreferrer"
					>
						Getting Started Documentation
					</a>
				</div>

				{/* Logo */}
				<div className="mb-5">
					<img className="mx-auto" src={LogoIMG} alt="Raiser Sync Logo" width={120} height={120} />
				</div>

				{/* Form */}
				<form
					onSubmit={async (e) => {
						e.preventDefault();

						const { data: res } = await getOrganisationBySecretKey(search);
						if (Boolean(res.data)) {
							localStorage.setItem('Secret-Key', search);
							navigate('/organisation');
						} else {
							setError('No results found!');
						}
					}}
				>
					<label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
						Search
					</label>
					<div className="relative">
						<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
							<SearchSVG />
						</div>
						<input
							type="search"
							id="default-search"
							className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							placeholder="Search Integration by Secret Key"
							value={search}
							onChange={(e) => {
								if (error) {
									setError('');
								}

								setSearch(e.target.value);
							}}
						/>
						<button
							type="submit"
							className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
						>
							Search
						</button>
					</div>
					<p className="text-sm font-bold mt-3 text-red-500">{error}</p>
				</form>

				<h3 className="text-2xl mt-20 mb-8 text-center font-bold">
					Let’s get your integration set up by connecting the two platforms below:
				</h3>

				<ul className="my-4 space-y-3">
					<li>
						<a
							href="/api/oauth/hubspot/url"
							className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
						>
							<HubspotSVG className="w-6 h-6" />
							<span className="flex-1 ml-3 whitespace-nowrap">HubSpot</span>
						</a>
					</li>
					<li>
						<a
							href="/api/oauth/blackbaud/url"
							className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
						>
							<BlackbaudSVG className="w-6 h-6" />
							<span className="flex-1 ml-3 whitespace-nowrap">Blackbaud</span>
						</a>
					</li>
				</ul>
			</Container>
		</div>
	);
};

export default DashboardPage;
